import React, { useEffect } from "react";
import Master from "../Include/Master";
import { useState } from "react";
import PieChart from "./PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../Redux/Action";
// import pieChart from "../../Component/src/assets/img/pie-chart.svg";
import pieChart from "../../Component/src/assets/img/pie-chart-white.svg";


const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardValues = useSelector(
    (state) => state?.reducerdata?.dashboard_data
  );
  console.log(dashboardValues,"dashboardValues");
  const [csscontrol, setCsscontrol] = useState("");
  console.log(csscontrol,"csscontrol");
  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };
  useEffect(() => {
    dispatch(getDashboardData());
  }, []);
  const a = 1;
  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Dashboard</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="row layout-top-spacing">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Total Users</h6>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <p className="value">{dashboardValues?.totaluser} Users</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Total Verified</h6>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    {dashboardValues?.verifiedUsers} Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Total Unverified</h6>
                </div>
              </div>
              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    {dashboardValues?.unVerifiedUsers} Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">This Week Registered</h6>
                </div>
              </div>
              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    {dashboardValues?.thisWeekUsers} Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">This month registered</h6>
                </div>
              </div>
              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    {dashboardValues?.thismonthsUsers} Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">This year registered</h6>
                </div>
              </div>
              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    {dashboardValues?.thisyearUsers} Users
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
            <div className="d-flex justify-content-between align-items-center">
              <div className="widget-heading">
                <h5 className="">Gender Ratio</h5>
              </div>
            </div>

            <div className="widget-content">
              <div id="chart-4" className={csscontrol=="sidebar-closed sbar-open"?"chart4-c ":"chart4-c collapse_chart"}>
              {
                dashboardValues?.maleUsers||
                    dashboardValues?.femaleUsers?
                  (   <PieChart
                  series={[
                    dashboardValues?.maleUsers,
                    dashboardValues?.femaleUsers,
                  ]}
                  labels={["Male", "Female"]}
                />):(
                   
                   <img src={pieChart} />
                 )}
                 {  dashboardValues?.maleUsers||
                    dashboardValues?.femaleUsers? (
                  ""
                ) : (
                  <div className="count-text-chart4 ">
                    <span>Total</span>
                    <p>0</p>
                  </div>
                )}
               
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
            <div className="d-flex justify-content-between align-items-center">
              <div className="widget-heading">
                <h5 className="">User Ratio</h5>
              </div>
            </div>

            <div className="widget-content">
              <div id="chart-4" className={csscontrol=="sidebar-closed sbar-open"?"chart4-c ":"chart4-c collapse_chart"}>
              {
                dashboardValues?.verifiedUsers||
                    dashboardValues?.unVerifiedUsers?
                  (  <PieChart
                  series={[
                    dashboardValues?.verifiedUsers,
                    dashboardValues?.unVerifiedUsers,
                  ]}
                  labels={["Verified", "Unverified"]}
                />):(
                   
                   <img src={pieChart} />
                 )}
                 {dashboardValues?.verifiedUsers||
                    dashboardValues?.unVerifiedUsers? (
                  ""
                ) : (
                  <div className="count-text-chart4 ">
                    <span>Total</span>
                    <p>0</p>
                  </div>
                )}
              
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
            <div className="d-flex justify-content-between align-items-center">
              <div className="widget-heading">
                <h5 className=""> Matches</h5>
              </div>
            </div>

            <div className="widget-content">
              <div id="chart-4" className={csscontrol=="sidebar-closed sbar-open"?"chart4-c ":"chart4-c collapse_chart"}>
              
                {dashboardValues?.female_male ||
                dashboardValues?.male_male ||
                dashboardValues?.female_female ? (
                  <PieChart
                    series={[
                      dashboardValues?.female_male,
                      dashboardValues?.male_male,
                      dashboardValues?.female_female,
                    ]}
                    labels={["Male -Female ", "Male - Male", "Female - Female"]}
                  />
                ) : (
                   
                  <img src={pieChart} />
                )}

                {dashboardValues?.female_male ||
                dashboardValues?.male_male ||
                dashboardValues?.female_female ? (
                  ""
                ) : (
                  <div className="count-text-chart4 ">
                    <span>Total</span>
                    <p>0</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default Dashboard;
