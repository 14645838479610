import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

const Profile2 = () => {
    const {id} = useParams();

    const userdetail = useSelector((state) => state.reducerdata.user_detail);

    const interestname1 = useSelector((state) => state.reducerdata.interest_list)

    const interestname = interestname1?.filter(item => userdetail?.interest_in?.includes(item._id));

  return (
    <div className='content'>
      <div className="page-meta">
                       <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                          <ol className="breadcrumb">
                             <li className="breadcrumb-item"><NavLink to="/user-management">User Management</NavLink></li>
                             <li className="breadcrumb-item active" aria-current="page">User Detail</li>
                          </ol>
                       </nav>
                    </div>

                    <div className="row layout-top-spacing"></div>
                       <div className="row layout-top-spacing">
                       <div className="col-md-7 mb-3">
                          <div className="widget-content widget-content-area br-8">
                        
                             <div className="detail_page_content">
                                   <div className="d-flex mb-2 align-items-center">
                                  
                                      <div className="main_profile_img">
                                     
                                      <img
                              src={process.env.REACT_APP_IMAGE_URL +
                              "user_document/" +userdetail?.main_image} />
                             </div>
                                      <div className="user-nam-details">
                                         <div className="d-flex user-name-part">
                                            <h4>{userdetail?.first_name + " " + userdetail?.last_name}<span> {userdetail?.age}</span></h4>
                                         </div>  
                                         <div className="gamer-name"> <label htmlFor="">Country :</label>{userdetail?.country_name}</div>
                                      <div className="gamer-name"> <label htmlFor="">Gender :</label>{userdetail?.gender}</div>                                    
                                      </div> 
                                   </div>
                                   
                                   <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="about-section">
                                            <div className="label-view">About</div>
                                            <div className="label-view-text">{userdetail?.about_me}</div>
                                        </div>
                                    </div>
                                   </div>

                                <div className="row mt-4 ">
                                   <div className="col-md-12">
                                    <div className="label-view">Interest</div>
                                    {interestname?.map((item,i) => {
                                       return (
                                         <ul className="list-none p-0" key={i}>
                                            <img src={process.env.REACT_APP_IMAGE_URL + "Icon/" + item?.icon} alt="icon " />
                                            <li className="badge badge-light-dark mb-2 me-4">{item?.name}</li>
                                            
                                         </ul>
                                           );
                                          })}
                                   </div>                                   
                                </div>
                                <div className="row mt-4 ">
                                    <div className="col-md-12">
                                        <div className="label-view">What I am looking For </div>
                                        <div className="label-view-text">{userdetail?.looking_for}</div>
                                       </div>
                                </div>
                                <div className="row mt-4">
                                   <div className="col-md-12">
                                      <h4 className="org-text mt-5">Additional inFormation</h4>
                                      <div className="course_info row other-dtails ">
                                         {/* <div className="col-md-12 mt-4">
                                            <div className="label-view">What I am looking For </div>
                                            <div className="label-view-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>   
                                         </div> */}
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Zodiac sign</div>
                                            <div className="label-view-text">{userdetail?.zodiac_sign}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Race</div>
                                            <div className="label-view-text">{userdetail?.race}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Sexual Orientation</div>
                                            <div className="label-view-text">{userdetail?.sexual_orientation}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Gender Identity</div>
                                            <div className="label-view-text">{userdetail?.gender_identity}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Drug Usage</div>
                                            <div className="label-view-text">No</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Vape / Smoke</div>
                                            <div className="label-view-text">{userdetail?.vape_smoke}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Drink Alcohol</div>
                                            <div className="label-view-text">{userdetail?.drink_alcohal}  </div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Love Language</div>
                                            <div className="label-view-text">{userdetail?.love_language}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Looking For</div>
                                            <div className="label-view-text">{userdetail?.looking_for}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Pronouns</div>
                                            <div className="label-view-text">{userdetail?.pronouns}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Work</div>
                                            <div className="label-view-text">{userdetail?.work}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">User is looking For</div>
                                            <div className="label-view-text">{userdetail?.user_looking_for}</div>   
                                         </div>
                                         <div className="col-md-6 mt-4">
                                            <div className="label-view">Intimacy preference</div>
                                            <div className="label-view-text">{userdetail?.intimacy_preference}</div>   
                                         </div>
                                         {/* <div className="col-md-6 mt-4">
                                            <div className="label-view">Social medias</div>
                                            <div className="label-view-text">Facebook</div>   
                                         </div> */}
                                      </div>
                                   </div>
                                
                                </div>
                              
                             </div>
                             
                                
                          </div>
                       </div>
                       <div className="col-md-5 mb-3">
                        <div className="widget-content widget-content-area">
                            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                <h4>Video</h4> 
                            </div>
                            <div className="video-part">
                                <video src={process.env.REACT_APP_IMAGE_URL + "user_document/" + userdetail?.video} alt="" width="570" height="450" controls="controls" autoplay="true" />
                            </div>
                        </div>
                        
                        <div className="widget-content widget-content-area mt-5">
                            <div >
                                <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>Gallery</h4> 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4">
                                    <a href="src/assets/img/lightbox-1.jpg" className="defaultGlightbox glightbox-content">
                                        <img src={process.env.REACT_APP_IMAGE_URL + "user_document/" + userdetail?.multiple_image} alt="image" className="img-fluid" />
                                    </a>
                                </div>
                                
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4">
                                    <a href="src/assets/img/lightbox-2.jpeg" className="defaultGlightbox glightbox-content">
                                        <img src={process.env.REACT_APP_IMAGE_URL + "user_document/" + userdetail?.multiple_image} alt="image" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4">
                                    <a href="src/assets/img/lightbox-3.jpeg" className="defaultGlightbox glightbox-content">
                                        <img src={process.env.REACT_APP_IMAGE_URL + "user_document/" + userdetail?.multiple_image} alt="image" className="img-fluid" />
                                    </a>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4">
                                    <a href="src/assets/img/lightbox-4.jpeg" className="defaultGlightbox glightbox-content">
                                        <img src={process.env.REACT_APP_IMAGE_URL + "user_document/" + userdetail?.multiple_image} alt="image" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                       </div>
                    </div>
    </div>
  )
}

export default Profile2
