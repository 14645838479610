import React, { useState } from "react";
import Master from "../Include/Master";
import { useEffect } from "react";
import { Userslist, activeUser, banuserAction } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";
import ReactPaginate from "react-paginate";
import { ShowprofileAction } from "../Redux/Action";
import PointsModal from "./PointsModal";
export default function Usermanagement() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [rewardPoint, setRewardPoint] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0); //Pagination

  useEffect(() => {
    dispatch(Userslist());
  }, []);
  useEffect(() => {
    dispatch(ShowprofileAction());
  }, []);

  const userlist = useSelector((state) => state.reducerdata.user_list);
  console.log(userlist);

  const bannedstatus = (status, id) => {
    dispatch(banuserAction({ status: status, id: id }));

    dispatch(Userslist());
  };
  const [csscontrol, setCsscontrol] = useState("");

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };

  const PER_PAGE = 10;
  const pageCount = Math.ceil(userlist?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * PER_PAGE;

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              // href="index.html"
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
              onClick={togalfuntionality}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Users Management</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>

      {/* Search Bar */}
      <div className="row">
        <div className="col-12 col-sm-6 d-flex justify-content-sm-start justify-content-center">
          <div className="dataTables_length" id="style-3_length"></div>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-end mt-sm-0 mt-3">
          <div id="style-3_filter" className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                aria-controls="style-3"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area white-space-none">
              <table
                id="style-3"
                className="table style-1 dt-table-hover non-hover"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Name</th>
                    {/* <th>Customers</th> */}
                    {/* <th>Email</th> */}
                    <th>Phone No</th>
                    <th>Gender</th>
                    {/* <th>Country </th> */}
                    <th>Age</th>
                    {/* <th>Points</th> */}
                    <th className="">Status</th>
                    <th className="text-center dt-no-sorting">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userlist?.length > 0 ? (
                    userlist
                      ?.filter((object) =>
                        search == ""
                          ? object
                          : object.first_name?.toLowerCase().includes(search) ||
                            object.last_name?.toLowerCase().includes(search) ||
                            object.email?.toLowerCase().includes(search)
                      )
                      ?.slice(offset, offset + PER_PAGE)
                      ?.map((user_object, i) => {
                        var today = new Date();
                        var birthDate = new Date(user_object?.birthdate);

                        var age_now =
                          today.getFullYear() - birthDate.getFullYear();
                        return (
                          <tr key={i}>
                            <td> {offset + i + 1} </td>
                            <td className="user-name d-flex">
                              <NavLink
                                className="profile-img"
                                to={`/user-profile/` + user_object?._id}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    "user_document/" +
                                    user_object?.main_image
                                  }
                                  alt="image"
                                />
                              </NavLink>
                            </td>
                            <td>
                              <div className="linebr name_space">
                                <NavLink
                                  to={`/user-profile/` + user_object?._id}
                                >
                                  {user_object?.first_name +
                                    " " +
                                    user_object?.last_name}
                                </NavLink>
                              </div>
                            </td>
                            {/* <td className="">
                          <a
                            className="profile-img"
                            href="javascript: void(0);"
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "user_document/" +
                                user_object?.main_image
                              }
                              alt="image"
                            />
                          </a>
                        </td> */}
                            {/* <td>
                                <div className="linebr">
                                  {user_object?.email}
                                </div>
                              </td> */}
                            <td>
                              {user_object?.country_code +
                                " " +
                                user_object?.phone_number}
                            </td>

                            <td>{user_object?.gender}</td>
                            <td>{age_now}</td>
                            {/* <td>
                                {user_object?.country_name
                                  ? user_object?.country_name
                                  : "N/A"}
                              </td> */}
                            {/* <td onClick={() =>{setModalShow(true);
                              setRewardPoint(user_object?.rewards[0])
                              
                              }}>

                                <p className="highlighted_link">{user_object?.totalRewardPoints}</p>
                              </td> */}
                            <td className="text-left">
                              {user_object?.status == 0 ? (
                                <span className="shadow-none badge badge-primary">
                                  Active
                                </span>
                              ) : user_object?.status == 1 ? (
                                <span className="shadow-none badge badge-primary">
                                  Temporarily Banned
                                </span>
                              ) : (
                                <span className="shadow-none badge badge-primary">
                                  Banned
                                </span>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink1"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-more-horizontal"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                  </svg>
                                </a>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink1"
                                >
                                  <a
                                    className={
                                      "dropdown-item " +
                                      (user_object?.status == 1 && " disable")
                                    }
                                    onClick={() => {
                                      bannedstatus(1, user_object._id);
                                    }}
                                  >
                                    Temporarily Ban
                                  </a>
                                  <a
                                    className={
                                      "dropdown-item " +
                                      (user_object?.status == 2 && " disable")
                                    }
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      bannedstatus(2, user_object._id);
                                    }}
                                  >
                                    Ban
                                  </a>
                                  <a
                                    className={
                                      "dropdown-item " +
                                      (user_object?.status == 0 && " disable")
                                    }
                                    onClick={() => {
                                      bannedstatus(0, user_object._id);
                                    }}
                                  >
                                    Active
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <p className="no_content">No Content Yet</p>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName="active"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </div>
          </div>
        </div>
      </div>

      <PointsModal
        setModalShow={setModalShow}
        modalShow={modalShow}
        rewardPoint={rewardPoint}
      />
    </Master>
  );
}
