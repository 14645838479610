import React, { useEffect, useState } from "react";
import Master from "../Include/Master";

import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Supportlist } from "../Redux/Action";

export default function Suppor() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Supportlist());
  }, []);

  const supportlist = useSelector((state) => state?.reducerdata?.support_list);
  console.log(supportlist,"supportlist");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [csscontrol, setCsscontrol] = useState("")

  const togalfuntionality = () => {

    setCsscontrol(csscontrol == 'sidebar-closed sbar-open' ? "" : 'sidebar-closed sbar-open')
  }

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Support</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="row layout-spacing mt-3">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <table
                id="style-3"
                className="table style-1 dt-table-hover non-hover"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Phone Number</th>
                    <th>Custom Message</th>
                    {/* <th className="dt-no-sorting">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {supportlist?.length > 0 ? supportlist?.map(function (user_object, i) {
                    return (
                      <tr key={i}>
                        <td> {i + 1} </td>
                        <td className="user-name">
                          {user_object?.user_id?.first_name +
                            " " +
                            user_object?.user_id?.last_name}
                        </td>
                        <td>{user_object?.user_id?.email}</td>
                        <td>{user_object?.user_id?.country_name == undefined ? "N/A" : user_object?.user_id?.country_name}</td>
                        <td>
                          {user_object?.user_id?.country_code +
                            " " +
                            user_object?.user_id?.phone_number}
                        </td>

                        <td>{user_object.description}</td>
                        {/* <td>
                          <button className="btn btn-light-dark">Close</button>
                          <button
                            className="btn drk_btn ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            Reply
                          </button>
                        </td> */}
                      </tr>
                    );
                  }) : (

                    <p className="no_content">No Content Yet</p>
 )}
                </tbody>
              </table>

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Reply
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <svg> ... </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row mb-4">
                        <div className="col-sm-12">
                          <label>Content</label>
                          <div id="blog-description"></div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn drk_btn">
                        Reply
                      </button>
                      <button type="button" className="btn btn btn-light-dark">
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={show} onHide={handleClose}>
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Reply
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <svg> ... </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row mb-4">
                        <div className="col-sm-12">
                          <label>Content</label>
                          <div id="blog-description"></div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn drk_btn">
                        Reply
                      </button>
                      <button type="button" className="btn btn btn-light-dark">
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
