import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Analytics from "./Component/Analytics/Analytics";
import Login from "./Component/Login/Login";
import Usermanagement from "./Component/UserManagement/Usermanagement";
import Support from "./Component/Support/Support";
import Communities from "./Component/Communities/Communities";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Component/Protected Route/Protectedroute";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import Notification from "./Component/Notifications/Notification";
import Userprofile from "./Component/User-profile/Userprofile";
import Profile2 from "./Component/Profile2/Profile2";
import ChangePassword from "./Component/ChangePassword/ChangePassword";
import RewardMng from "./Component/reward_mng/RewardMng";
import Dashboard from "./Component/dashboard/Dashboard";
import ReportReasons from "./Component/report_reasaons/ReportReasons";
import BlockReason from "./Component/block_reason/BlockReason";
import Interests from "./Component/interests/Interests";
import Successurl from "./Component/getOtp/SuccessUrl";
import Failedurl from "./Component/getOtp/FailedUrl";
function App() {
  var authentication = sessionStorage.getItem("authentication");

  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          path="/analytics"
          element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedRoute>
              <Usermanagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <Support />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reward-management"
          element={
            <ProtectedRoute>
              <RewardMng />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/notification"
          element={
            <ProtectedRoute>
              <Notification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-reasons"
          element={
            <ProtectedRoute>
              <ReportReasons />
            </ProtectedRoute>
          }
        />
        <Route
          path="/block-reasons"
          element={
            <ProtectedRoute>
              <BlockReason />
            </ProtectedRoute>
          }
        />
           <Route
          path="/interests"
          element={
            <ProtectedRoute>
              <Interests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-profile/:id"
          element={
            <ProtectedRoute>
              <Userprofile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/communities"
          element={
            <ProtectedRoute>
              <Communities />
            </ProtectedRoute>
          }
        />

        <Route
          path="/changePassword"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />

        <Route path="/profile2" element={<Profile2 />} />
        <Route path="/success-url" element={<Successurl />} />
        <Route path="/failed-url" element={<Failedurl />} />

        {!authentication ? (
          <Route path="/" element={<Login />} />
        ) : (
          <Route path="*" element={<Navigate to="/user-management" />} />
        )}
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
