import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Master from "../Include/Master";
import { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import {
  addInterests,
  addNotifications,
  deleteInterests,
  deleteNotifications,
  getInterestList,
  getNotifications,
} from "../Redux/Action";
import ReactPaginate from "react-paginate";
const Interests = () => {
  const [id, setId] = useState("");
  const [logo, setLogo] = useState("");
  const [update, setUpdate] = useState(false);
  const [preview, setPreview] = useState();
  const [ViewImg, setViewImg] = useState();
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0); //Pagination
  const [show, setShow] = useState(false);
 
  const dispatch = useDispatch();

  const interestList = useSelector(
    (state) => state?.reducerdata?.interest_list
  );
  useEffect(() => {
    dispatch(getInterestList());
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Enter name";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("id", "");
      formData.append("icon", logo);
      try {
        await dispatch(addInterests(formData));
        dispatch(getNotifications());
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [csscontrol, setCsscontrol] = useState("");

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };

  const PER_PAGE = 6;
  const pageCount = Math.ceil(interestList?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * PER_PAGE;

  const updateInterest = (value) => {
    setUpdate(true);
    setViewImg(value.icon);
    formik.setFieldValue("name", value.name);
    setShow(true);
  };
  const handleImageUpdate = (e) => {
    setLogo(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <Master func={csscontrol}>
      {/* <!-- BREADCRUMB --> */}
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              // href="javascript:void(0);"
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
              onClick={togalfuntionality}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Interests</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-6 d-flex justify-content-sm-start justify-content-center">
          <div className="dataTables_length" id="style-3_length">
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="primary"
                onClick={() => {
                  setUpdate(false)
                  setShow(true);
                  formik.resetForm();
                }}
              >
                Add Interest
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 d-flex justify-content-end mt-sm-0 mt-3">
          {/* <div id="style-3_filter" className="dataTables_filter">
                    <label><input type="search" className="form-control" placeholder="Search..." aria-controls="style-3" onChange={(e)=>{
                      setSearch(e.target.value)
                    }} /></label>
                    </div> */}
        </div>
      </div>

      <div className="row layout-top-spacing">
        <div className="ccol-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <table
                id="zero-config style-3"
                className="table style-1 dt-table-hover non-hover sticky-head-second"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {interestList?.length > 0 ? (
                    interestList
                      ?.filter((object) =>
                        search == ""
                          ? object
                          : object.title.toLowerCase().includes(search)
                      )
                      ?.slice(offset, offset + PER_PAGE)
                      ?.map((item, i) => {
                        return (
                          <tr>
                            <td>{offset + i + 1}</td>
                            <td>
                              {" "}
                              <img
                                alt="avatar"
                                className="img-fluid  img_fit_cover"
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "icon/" +
                                  item.icon
                                }
                              />
                            </td>
                            <td className="width-line-break"><p>{item?.name}</p></td>
                            {/* <td>
                              <p className="line_break">{item?.description}</p>
                            </td> */}

                            {/* <td>
                              {item.gender && item.gender == 1
                                ? "All Male"
                                : item.gender == 3
                                ? "All Users"
                                : "All Female"}
                            </td> */}
                            <td className="custom_pd">
                              <a
                                className="delete_ml"
                                onClick={() => updateInterest(item)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a
                                onClick={() => {
                                  setShowdelete(true);
                                  setId(item?._id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <p className="no_content">No Content Yet</p>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName="active"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showdelete}
        onHide={handleClosedelete}
        size="sl"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(deleteInterests(id));
              dispatch(getInterestList());
              setShowdelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- Modal --> */}
      <Modal show={show}>
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <Modal.Header
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <Modal.Title>
              {update ? "Update interest" : "Add interest"}
            </Modal.Title>
            <Button
              type="button"
              className="btn-close btn_cross"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() =>
              {
                formik.resetForm();
               setShow(false)}
              }
            ></Button>
          </Modal.Header>

          <Modal.Body>
            <form action="">
              <div className="row">
                {/* <div className="col-md-12 mb-3">
                <div className="contact-name">
                  <select
                    id=""
                    className="form-control"
                    onChange={(e) =>
                      formik.setFieldValue("gender", e.target.value)
                    }
                  >
                    <option value="" selected>
                      Select Users
                    </option>
                    <option value="1">All Male</option>
                    <option value="0">All Female</option>
                    <option value="2">All Other</option>
                    <option value="3">All Users</option>
                  </select>
                  <span className="validation-text"></span>
                </div>
              </div> */}
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <label>Name</label>
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      name="name"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Name"
                    />
                    <span className="validation-text">
                      {formik.errors.name ? (
                        <p style={{ color: "red" }}>{formik.errors.name}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  {update ? (
                    <div className="contact-email small-popup-img-upload">
                       <label>Add Image</label>
                      <input
                        onChange={handleImageUpdate}
                        name="quest"
                        type="file"
                        className="form-control"
                        placeholder="Upload Photo"
                      />
                      <div className="view-small-upload top_120">
                          <img
                            className="view-small-upload"
                            alt="img"
                            src={
                              preview?preview:
                              process.env.REACT_APP_IMAGE_URL +
                              "icon/" +
                              ViewImg
                            }
                          />
                      </div>
                      <span className="validation-text"></span>
                    </div>
                  ) : (
                    <div className="contact-email">
                       <label>Add Image</label>
                      <input
                        onChange={(e) => setLogo(e.target.files[0])}
                        name="icon"
                        type="file"
                        id=""
                        className="form-control"
                        placeholder="Upload Logo"
                      />
                      <span className="validation-text"></span>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {/* <!-- <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Discard</button> --> */}

            {formik.values.name && logo ? (
              <Button
                type="submit"
                className="btn drk_btn"
                onClick={() => setShow(false)}
              >
                Add
              </Button>
            ) : (
              <Button type="submit" className="btn drk_btn">
                Add
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {/* delete modal */}
      
    </Master>
  );
};

export default Interests;
