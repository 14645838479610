module.exports = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  USER_LIST: "USER_LIST",
  SUPPORT_LIST: "SUPPORT_LIST",
  ADD_COMMUNITY: "ADD_COMMUNITY",
  COMMUNITY_LIST: "COMMUNITY_LIST",
  DELETE_LIST: "DELETE_LIST",
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  DELETE_NOTIFY: "DELETE_NOTIFY",
  USER_DETAIL: "USER_DETAIL",
  INTEREST_LIST: "INTEREST_LIST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  ADMIN_REWARD_POINTS: "ADMIN_REWARD_POINTS",
  ADMIN_DASHBOARD_DATA:"ADMIN_DASHBOARD_DATA",
  ANALYTIC_LIST:"ANALYTIC_LIST",
  REPORT_LIST:"REPORT_LIST",
  INTEREST_LIST_NEW:"INTEREST_LIST_NEW",
  DELETE_INTEREST:"DELETE_INTEREST",
  BLOCKS_USER_LIST:"BLOCKS_USER_LIST"

};
