import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import { Button } from "react-bootstrap";

export default function Successurl() {
  const localUrl = "http://localhost:4066/api/verifygetOtp";
  const liveUrl = "https://wedate-api.codobux.com/api/verifygetOtp";
  
  const id = new URLSearchParams(useLocation().search);

  const otpid = id.get("otp_id");
  console.log(otpid,"otpid")

  useEffect(() => {
    handleClick();
  }, []);

  const handleClick = async () => {
    if (!otpid) {
      console.error("OTP ID is missing");
      return;
    }

    try {
      const response = await axios.post(liveUrl, { otp_id: otpid });
      console.log(response.data, "Data received from the API");
      // Handle success response if needed
    } catch (error) {
      console.error("Error during API call:", error);
      // Optionally display error message to the user
    }
  };

  return (
    <>
      <div className="right-side" style={{ position: "relative" }}>
        <div className="main-content">
          <div className="appointment-created-img">
            <img src={require("../src/assets/img/appointment-created.png")} alt="Appointment Created" />
          </div>
          <div className="appointment-created-txt">
            <h2>
              Your OTP has
              <br />
              been confirmed!
            </h2>
            <p className="invoice-para">
              {/* You can include additional information here if needed */}
            </p>
          </div>
          {/* <div className="invoice-details-link">
            <Button  >
              Done
            </Button>
          </div> */}
        </div>
      </div>
    </>
  );
}
