import React from "react";
import Master from "../Include/Master";
import { useState } from "react";
import GenderGraph from "./graphs/GenderGraph";
import Graph from "./graphs/Graph";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Userslist, getAnalytics } from "../Redux/Action";

export default function Analytics() {
  const [csscontrol, setCsscontrol] = useState("");
  const [graphVal, setGraphVal] = useState({
    age: "",
    looking_for: "",
    zodiac: "",
    interest: "",
    gender: "",
    ethnicity: "",
    country: "",
    status:""
  });
  const [dataVal, setDataVal] = useState({
    ageData: "",
    looking_forData: "",
    zodiacData: "",
    interestData: "",
    genderData: "",
    ethnicityData: "",
    counrtyData: "",
    statusData:""
  });

  const dispatch = useDispatch();
  const analyticUserDat = useSelector(
    (state) => state?.reducerdata?.analytic_list
  );
  console.log(analyticUserDat, "analyticUserDat");

  const analyticAgeData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.ageObject
  );

  const analyticMarital = useSelector(
    (state) => state?.reducerdata?.analytic_list?.maritalstatusobj
  );
  const analyticRegisterReasonData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.registration_reasoneObj
  );
  const analyticInterestData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.interestObj
  );
  const analyticGenderData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.genderObject
  );
  const analyticEthnicityData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.ethinicityobj
  );
  const analyticCountryData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.countryobj
  );
  const analyticStatusData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.statusobj

  ); 

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };
  useEffect(() => {
    dispatch(getAnalytics());
  }, []);

  useEffect(() => {
    if (analyticAgeData != undefined) {
      var ageCountData = Object.keys(analyticAgeData)?.map(
        (key) => analyticAgeData[key]
      );
      var data = Object.keys(analyticAgeData).map((key) => key);
    }
    if (analyticMarital != undefined) {
      var maritalData = Object.keys(analyticMarital).map(
        (key) => analyticMarital[key]
      );
      var data1 = Object.keys(analyticMarital).map((key) => key);
    }

    if (analyticRegisterReasonData != undefined) {
      var zodiacData = Object.keys(analyticRegisterReasonData).map(
        (key) => analyticRegisterReasonData[key]
      );
      var data2 = Object.keys(analyticRegisterReasonData).map((key) => key);
    }

    if (analyticInterestData != undefined) {
      var interestData = Object.keys(analyticInterestData).map(
        (key) => analyticInterestData[key]
      );
      var data3 = Object.keys(analyticInterestData).map((key) => key);
    }
    if (analyticGenderData != undefined) {
      var genderData = Object.keys(analyticGenderData).map(
        (key) => analyticGenderData[key]
      );
      var data4 = Object.keys(analyticGenderData).map((key) => key);
    }
    if (analyticEthnicityData != undefined) {
      var ethnicityData = Object.keys(analyticEthnicityData).map(
        (key) => analyticEthnicityData[key]
      );
      var data5 = Object.keys(analyticEthnicityData).map((key) => key);
    }
    if (analyticCountryData != undefined) {
      var countryData = Object.keys(analyticCountryData).map(
        (key) => analyticCountryData[key]
      );
      var data6 = Object.keys(analyticCountryData).map((key) => key);
    }
    if (analyticStatusData != undefined) {
      var statusData = Object.keys(analyticStatusData).map(
        (key) => analyticStatusData[key]
      );
      var data7 = Object.keys(analyticStatusData).map((key) => key);
    }
    setGraphVal((prevValue) => {
      return {
        ...prevValue,
        age: ageCountData,
        looking_for: maritalData,
        zodiac: zodiacData,
        gender: genderData,
        interest: interestData,
        ethnicity: ethnicityData,
        country: countryData,
        status:statusData
      };
    });

    setDataVal((prevValue) => {
      return {
        ...prevValue,
        ageData: data,
        looking_forData: data1,
        zodiacData: data2,
        genderData: data4,
        interestData: data3,
        ethnicityData: data5,
        counrtyData: data6,
        statusData:data7
      };
    });
  }, [
    analyticAgeData,
    analyticMarital,
    analyticRegisterReasonData,
    analyticInterestData,
    analyticGenderData,
  ]);

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Analytics</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="row layout-top-spacing">
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Registeration reason Graph</h4>
                  </div>
                </div>
                <Graph graph={graphVal?.zodiac} data={dataVal?.zodiacData} />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart3" className=""></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Relationship status Graph</h4>
                  </div>
                </div>
                <Graph
                  graph={graphVal?.looking_for}
                  data={dataVal?.looking_forData}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart2" className=""></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartBar" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Gender Graph</h4>
                  </div>
                </div>
                <GenderGraph
                  genderData={dataVal?.genderData}
                  genderGraphData={graphVal?.gender}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="s-bar" className=""></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Interest Graph</h4>
                  </div>
                </div>
                <Graph
                  graph={graphVal?.interest}
                  data={dataVal?.interestData}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart" className=""></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Age Graph</h4>
                  </div>
                </div>
                <Graph graph={graphVal?.age} data={dataVal?.ageData} />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart4" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Ethnicity Graph</h4>
                  </div>
                </div>
                <Graph
                  graph={graphVal?.ethnicity}
                  data={dataVal?.ethnicityData}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart4" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Country Graph</h4>
                  </div>
                </div>
                <Graph graph={graphVal?.country} data={dataVal?.counrtyData} />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart4" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Status Graph</h4>
                  </div>
                </div>
                <Graph graph={graphVal?.status} data={dataVal?.statusData} />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart4" className=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
