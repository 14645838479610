import React from 'react'

export default function PrivacyPolicy() {
    
  return (
    <div class="container">
    <div class="privacy_text">
      
            <h3>Privacy Policy</h3>
            <p>
                At Matchual, we take your privacy very seriously. We are committed to protecting your personal information and ensuring that it is kept secure at all times. This privacy policy outlines how we collect, use, and disclose your information when you use our dating app.</p>
            <p>
                Information we collect We collect certain personal information when you sign up for Matchual. </p>
            <p>
                This may include your name, email address, gender, date of birth, and location. We may also collect information about your interests and preferences, as well as your communication history on the app.  </p>
          
            <p>
                How we use your information We use the information we collect to provide you with a personalized and enjoyable dating experience on our app. We may also use your information to communicate with you, to analyze how our app is used, and to improve our services.</p>
         
            <p>
                Sharing of information We do not share your personal information with third parties without your consent, except as required by law. We may share your information with service providers who assist us in providing our services, such as payment processors and hosting providers.</p>
             <p>
                    We require these service providers to use your information only for the purposes of providing services to us and to protect your personal information.
                </p>
            <p>
                Security of information We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. We use industry-standard security technologies and procedures to safeguard your information.</p>
            <p>
                Your rights You have the right to access and correct your personal information at any time.</p>    
                
            <p>
                You may also request that we delete your personal information from our records. If you have any questions or concerns about our use of your information, please contact us at support@matchual.com.</p>
           
            <p>
                Changes to our privacy policy We reserve the right to update or modify this privacy policy at any time. If we make any material changes to this policy, we will notify you by email or through the app. Your continued use of Matchual after any changes to this policy will constitute your acceptance of the revised policy.</p>

        </div> 

</div>
  )
}
