import React from "react";
import { Link } from "react-router-dom";
import "../../App.css"

export default function Failedurl() {
  return (
    <>
      {/* <Layout > */}
      {/* <Master> */}
        <div className="right-side" style={{ position: "relative" }}>
          {/* {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )} */}

          <div className="main-content">
            <div className="appointment-created-img">
              <img src={require("../src/assets/img/appointment-created.png")} />
            </div>
            <div className="appointment-created-txt">
              <h2>
                Your OTP is incorrect
                <br />
                Please try again!
              </h2>
              <p className="invoice-para">
                {/* A request has been sent to the coach. Next, the coach must
                process the request. You will receice a message as soon as the
                coach has accepted or rejected the request.
                <br />
                successfully. */}
              </p>
            </div>
            <div
              // onClick={() => {
              //   // if (location?.state?.autoApprove == 1) {
              //     mutate({
              //       appointment_id: appointmentId,
              //       payment_intent: paymnetIntent?.data?.id,
              //       langType: ingerman == "de" ? 1 : 0,
              //     });
              //   // }else{

              //   // }
              // }}
              className="invoice-details-link"
            >
              <Link
              // to="/coaching"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      {/* </Master> */}
      {/* </LayoutNew> */}
    </>
  );
}
