import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  return (
    <nav id="sidebar">
      <div className="navbar-nav theme-brand flex-row  text-center">
        <div className="nav-logo">
          <div className="nav-item theme-logo">
            <NavLink to="/analytics">
              <img
                src={require("../src/assets/img/logo2.svg").default}
                className="navbar-logo"
                alt="logo"
              />
            </NavLink>
          </div>
        </div>
        <div className="nav-item sidebar-toggle">
          <div className="btn-toggle sidebarCollapse">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </div>
        </div>
      </div>
      <div className="shadow-bottom"></div>
      <ul className="list-unstyled menu-categories" id="accordionExample">
        <li
          className={
            window.location.pathname == "/dashboard" ? " menu active" : "menu"
          }
        >
          <NavLink
            to="/dashboard"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>Dashboard</span>
            </div>
          </NavLink>
        </li>

        <li
          className={
            window.location.pathname == "/analytics" ? " menu active" : "menu"
          }
        >
          <NavLink
            to="/analytics"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-sliders"
              >
                <line x1="4" y1="21" x2="4" y2="14"></line>
                <line x1="4" y1="10" x2="4" y2="3"></line>
                <line x1="12" y1="21" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="3"></line>
                <line x1="20" y1="21" x2="20" y2="16"></line>
                <line x1="20" y1="12" x2="20" y2="3"></line>
                <line x1="1" y1="14" x2="7" y2="14"></line>
                <line x1="9" y1="8" x2="15" y2="8"></line>
                <line x1="17" y1="16" x2="23" y2="16"></line>
              </svg>
              <span>Analytics</span>
            </div>
          </NavLink>
        </li>
        <li
          className={
            window.location.pathname == "/user-management"
              ? " menu active"
              : "menu"
          }
        >
          <NavLink
            to="/user-management"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Users Management</span>
            </div>
          </NavLink>
        </li>

        <li
          className={
            "menu " +
            (window.location.pathname == "/report-reasons" ? "active" : "")
          }
        >
          <NavLink
            to="/report-reasons"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="feather feather-user"
              >
                <path
                  fill="currentColor"
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"
                />
                <path fill="currentColor" d="M11 11h2v6h-2zm0-4h2v2h-2z" />
              </svg>

              <span>Report Reasons</span>
            </div>
          </NavLink>
        </li>

        <li
          className={
            "menu " +
            (window.location.pathname == "/block-reasons" ? "active" : "")
          }
        >
          <NavLink
            to="/block-reasons"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="feather feather-user"
              >
                <path
                  fill="currentColor"
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zM4 12c0-1.846.634-3.542 1.688-4.897l11.209 11.209A7.946 7.946 0 0 1 12 20c-4.411 0-8-3.589-8-8zm14.312 4.897L7.103 5.688A7.948 7.948 0 0 1 12 4c4.411 0 8 3.589 8 8a7.954 7.954 0 0 1-1.688 4.897z"
                />
              </svg>
              <span>Block Reasons</span>
            </div>
          </NavLink>
        </li>
        <li
          className={
            "menu " + (window.location.pathname == "/interests" ? "active" : "")
          }
        >
          <NavLink
            to="/interests"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                class="feather feather-user"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M22 8.65A5 5 0 0 0 17 4H7a5 5 0 0 0-5 4.74A2 2 0 0 0 2 9v7.5A3.48 3.48 0 0 0 5.5 20c1.43 0 2.32-1.06 3.19-2.09c.32-.37.65-.76 1-1.1a4.81 4.81 0 0 1 1.54-.75a6.61 6.61 0 0 1 1.54 0a4.81 4.81 0 0 1 1.54.75c.35.34.68.73 1 1.1c.87 1 1.76 2.09 3.19 2.09a3.48 3.48 0 0 0 3.5-3.5V9a2.09 2.09 0 0 0 0-.26zm-2 7.85a1.5 1.5 0 0 1-1.5 1.5c-.5 0-1-.64-1.66-1.38c-.34-.39-.72-.85-1.15-1.26a6.68 6.68 0 0 0-2.46-1.25a6.93 6.93 0 0 0-2.46 0a6.68 6.68 0 0 0-2.46 1.25c-.43.41-.81.87-1.15 1.26C6.54 17.36 6 18 5.5 18A1.5 1.5 0 0 1 4 16.5V9a.77.77 0 0 0 0-.15A3 3 0 0 1 7 6h10a3 3 0 0 1 3 2.72v.12A.86.86 0 0 0 20 9z"
                />
                <circle cx="16" cy="12" r="1" fill="currentColor" />
                <circle cx="18" cy="10" r="1" fill="currentColor" />
                <circle cx="16" cy="8" r="1" fill="currentColor" />
                <circle cx="14" cy="10" r="1" fill="currentColor" />
                <circle cx="8" cy="10" r="2" fill="currentColor" />
              </svg>
              <span>Interests</span>
            </div>
          </NavLink>
        </li>
        <li
          className={
            "menu " +
            (window.location.pathname == "/notification" ? "active" : "")
          }
        >
          <NavLink
            to="/notification "
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>

              <span>Notifications</span>
            </div>
          </NavLink>
        </li>
        <li
          className={
            "menu " + (window.location.pathname == "/support" ? "active" : "")
          }
        >
          <NavLink
            to="/support"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-help-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <span>Support</span>
            </div>
          </NavLink>
        </li>
        {/* <li
          className={
            "menu " +
            (window.location.pathname == "/reward-management" ? "active" : "")
          }
        >
          <NavLink
            to="/reward-management"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-gift"
              >
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
              </svg>
              <span>Reward Management</span>
            </div>
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
}
